import React from "react";
import { graphql } from "gatsby";

import { Layout } from "../../components/layout"
import {
    GridFour,
    GridOneText,
    PicturedTextLink,
    HorSep,
} from "../../components/grids";
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { H4UppercaseGrey } from "../../components/headings";

export default function Products({ data }) {
    let content = []
    data.allContentfulProduct.nodes.forEach(elem => {
        if (elem.node_locale == "en") {
            content.push({
                heading: elem.productHeading,
                text: elem.productDescription,
                picture: elem.productImage
            })
        }
    })
    const options = {
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => <H4UppercaseGrey>{children}</H4UppercaseGrey>,
        }
    }

    return (
        <Layout title="Products" lang="en" actSite="products/">
            <HorSep height="150px" />
            <GridOneText>
                {documentToReactComponents(data.products.content.json, options)}
            </GridOneText>
            <GridFour
                left={
                    <PicturedTextLink
                        heading={content[0].heading}
                        picture={content[0].picture.fluid}
                        text={content[0].text}
                        to=""
                    />
                }
                centerLeft={
                    <PicturedTextLink
                        heading={content[1].heading}
                        picture={content[1].picture.fluid}
                        text={content[1].text}
                        to=""
                    />
                }
                centerRight={
                    <PicturedTextLink
                        heading={content[2].heading}
                        picture={content[2].picture.fluid}
                        text={content[2].text}
                        to=""
                    />
                }
                right={
                    <PicturedTextLink
                        heading={content[3].heading}
                        picture={content[3].picture.fluid}
                        text={content[3].text}
                        to=""
                    />
                }
            />
        </Layout>
    );
}

// Query pictures for lazy loading
export const query = graphql`
    query {
        allContentfulProduct {
            nodes {
                productDescription
                productHeading
                productImage {
                    fluid {
                        src
                        ...GatsbyContentfulFluid
                    }
                    title
                }
                node_locale
            }
        }
        products: contentfulTextOnly(title: {eq: "Products"}, node_locale: {eq: "en"}) {
            content {
                json
            }
        }
    }
`;
